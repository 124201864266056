.form-preview {
  min-width: 500px;
  .modal-body {
    position: relative;

    iframe {
      min-height: 500px;
      min-width: 500px;
    }

    .overlay {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: pink;
      opacity: 0;
    }
  }
}