@import "../../../styles/base/_variables.scss";
.colorBox {
  width: 48px;
  height: 48px;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-box;
}

.steps-order {

  .step-item {
    border: 1px solid $color13;
    background-color: $color14;
    
    position: relative;
    width: 100%;
    border-radius: 10px;

    .delete-button {
      position: absolute;
      display: none;
      top: 1em;
      right: 12px;
    }
  }
}