@import "../../styles/base/_variables.scss";

.results-table {
  color: $color10;

  tr {
    color: $color10;
  }

  tbody {
    tr.clickable-row.odd {
      background: $color8; 
    }

    tr:not(.clickable-row) {
      background: $color1; 
    }

    tr.top-opened {
      border-top: 2px solid #fff;
      td {
        border-bottom: none;
      }
    }

    tr.bottom-opened {
      border-bottom: 2px solid #fff;
      background: none;

      &.odd {
        background: #222;
      }
    }
  }

  .clickable-row {
    cursor: pointer;
  }
}