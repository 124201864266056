@import '../../styles/base/_variables.scss';

.landing-page {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  padding-top: 50px;
  color: $color10;
  background-color: $color9;

  .header {
    padding: 0;
    margin-bottom: 150px;
    .logo {
      padding: 0;
      width: 140px;
    }

    .login-box {
      position: relative;

      .btn {
        position: absolute;
        top: -17px;
        right: 0;
        background-color: $color14;
        border-color: $color14;
        line-height: 34px;
        padding: 0 30px;
        border-radius: 17px;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .highlights {
    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -0.03em;
      max-width: 430px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 120%;
      letter-spacing: -0.03em;
      max-width: 270px;
      display: inline-block;
      margin-top: 10px;
    }
  }

  .request-form {
    margin-top: 60px;
    
    .form-container {
      display: flex;
      width: 428px;
      height: 44px;
      background-color: $color11;
      border-radius: 22px; 
      font-size: 14px;
      
      .btn {
        background-color: $color14;
        border-color: $color14;
        border-width: 0;
        border-radius: 17px;
        padding: 0;
        width: 160px;
        margin-top: 5px;
        height: 34px;
        line-height: 34px;
        font-size: 14px;
        text-align: center;
      }

      .form-control {
        margin-right: 10px;
        width: 250px;
        font-size: 14px;
        background-color: transparent;
        border-width: 0;
        padding-left: 30px;
        color: $color10;
      }

      margin-bottom: 30px;

    }
  }

  .hero-image {
    width: 547px;
    display: block;
    margin-left: auto;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .landing-page {
    .highlights {
      flex-direction: column-reverse;

      .content {
        width: 450px;
        margin: 40px auto;
      }
    }

    .hero-image {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 547px;
    }
  }
}