@import "../fonts/Poppins/font";

@mixin defaultFont {
  font-family: Poppins;
}

@mixin defaultBoldFont {
  font-family: PoppinsBold;
}

@mixin font1 {
  color: $color10;
  @include defaultFont;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
}

@mixin font2 {
  color: $color10;
  @include defaultFont;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}

@mixin font3 {
  color: $color10;
  @include defaultFont;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

@mixin font4 {
  color: red;
  @include defaultFont;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

@mixin font5 {
  color: $color10;
  @include defaultFont;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
}

@mixin header1 {
  color: $color10;
  @include defaultFont;
  font-size: 35px;
  line-height: 35px;
  font-style: normal;
  font-weight: normal;
}
