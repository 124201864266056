@import "../../styles/base/_variables.scss";


.language-selector {
  .dropdown-menu {
    width: 200px;
    color: $color12;
    background-color: $color11;
    
    p {
      margin-left: 10px;
      margin-bottom: 8px;

      &.non-localized {
        margin-top: 12px;
      }
    }

    .item-row {
      height: 40px;

      button {
        color: $color12;
        margin-left: 13px;

        &.dropdown-item {
          width: auto;
          display: inline-block;
          
          &:hover {
            background-color: $color14;
            color: $color9;
            border-radius: 10px;
           // margin: 0;
            margin-left: 13px;

          }
        }
      }

      .non-localized-item {
        display: inline-block;
        width: auto;
        padding: 4px 16px;
        margin-left: 13px;
        line-height: 32px;
      }
    
      .icon {
        display: none;
        text-align: right;
        width: 40px;
        height: 40px;
      }

      &:hover {
        .icon {
          display: block;
          cursor: pointer;
        }
      }
    }
  }
}