
.action-edit-button.btn {
  padding: 2px 8px;
  float: right;
  margin-top: -6px;
  font-size: 12px;
  border-radius: 10px;
}

.action-selection {
  .default {
    font-weight: bold;
  }
}
