@import "../../styles/base/index.scss";

.form-header {
  max-width: 600px;
  display: flex;
  margin: 0 auto;

  .logo {
    padding: 0;
    margin: 20px
  }
}

.form-layout {


}
