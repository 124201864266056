@import '../../styles/base/_variables.scss';

.onboarding {
  margin: 0 auto;
  h1, p {
    text-align: center;
  }

  h1 {
    font-size: 21px;
  }

  a, a:hover, a:visited {
    color: $color14;
  }

  .row {
    padding-left: 6px;
    padding-right: 6px;
    margin-bottom: 50px;

    .col {
      .btn {
        text-align: center;
          display: inline-block;
      }
    }
  }

  .code-box {
    position: relative;

    .copy-button {
      position: absolute;
      top: 68px;
      right: 6px;
      width: 40px;
      height: 40px;
    }

    pre.code {
      border: 1px solid $color10;
      padding: 12px;
      margin: 12px;
      min-height: 72px;
    }
  }

  ul.nav {
    margin: 0;
    margin-bottom: 24px;

    .nav-link {
      cursor: pointer;
    }
  }
  
  .form-control {
    background-color: transparent;
    border: 1px solid $color11;
    padding: 12px;
    color: $color10;
  }

  .btn {
    width: auto;
    padding: 12px 24px;
    border-radius: 30px;
  }

  .color-box {
    margin-bottom: 6px;
    width: 200px;

    .label {
      margin-bottom: 12px;
      text-align: left;
      padding-left: 0;
    }

    .input-preview {
      display: flex;
      
      .colorBox {
        width: 48px;
      }

      input {
        width: calc(100% - 60px);
        margin-left: 12px;
      }
    }
  }

  .form-preview {
    height: 100%;
  }

  .sdk-buttons {
    max-width: 600px;
    margin: 0 auto;

    .col {
      text-align: center;
    }
  }

  .sdk-button {
    margin: 0 auto;
    cursor: pointer;
    width: 150px;
    padding: 8px;
    background-color: $color11;
    display: inline-block;
    text-align: left;

    .logo {
      text-align: center;
      vertical-align: left;
      display: inline-block;
      background-color: $color13;
      width: 31px;
      height: 31px;
      padding: 0;
      line-height: 31px;
      border-radius: 3px;
    }

    .text {
      width: calc(100% - 44px);
      display: inline-block;
      margin-left: 12px;
      text-align: center;
    }
  }
}

.centered {
  text-align: center;
}