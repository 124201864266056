@import "../../styles/base/index.scss";

.button {
  @include defaultBtn;
  @include defaultBoldFont;
  border: 1px solid transparent;
  height: 40px;

  &_type {
    &_default {
      background: $color10;
      color: $color9;
      border: none;

      &:focus,
      &:hover,
      &:active {
        background: $color4Focus;
      }

      &.button_type_disabled {
        color: $color9;
        cursor: default;
        background: $color10;

        &:focus,
        &:hover,
        &:active {
          color: $color9;
        }
      }
    }

    &_outline {
      border: 1px solid $color4;
      color: $color4;
      background: $color9;

      &:focus,
      &:hover,
      &:active {
        border: 1px solid $color4Focus;
        color: $color4Focus;
      }

      &.button_type_disabled {
        color: $color10;
        border: 1px solid $color10;
        cursor: default;

        &:focus,
        &:hover,
        &:active {
          color: $color10;
          border: 1px solid $color10;
        }
      }
    }

    &_simple {
      color: $color10;
      background: $color9;
      @include defaultFont;

      &:focus,
      &:hover,
      &:active {
        color: $color4Focus;
      }

      &.button_type_disabled {
        color: $color10;
        cursor: default;

        &:focus,
        &:hover,
        &:active {
          color: $color10;
        }
      }
    }
  }
}
