$mobile-max-width: 1199px;
$color1: #444444;
$color2: #000000;
$color3: #f2f2f2;
$color4: #000000;
$color4Focus: #000000;
$color5: #d2d2d2;
$color6: #999797;
$color7: #faf3fb;
$color8: #222222;

$color9: #242529; // background color
$color10: #FAECDF; // fonts color
$color11: #34353B; // dropdown color
$color12: #DCDCDC; // audience background
$color13: #606872;
$color14: #FDF38B;
$color15: #B0ADA9;