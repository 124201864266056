@import "../../styles/base/_variables.scss";

.summaries {
  margin-top: 2em;
}

.what-to-build-summary {
  background-color: $color11;
  padding: 16px !important;
  border-radius: 10px;
  margin-bottom: 16px;

  .wtb-header {
    color: $color15;
    cursor: pointer;
    position: relative;

    .delete-icon {
      position: absolute;
      top: 0;
      right: 16px;
    }

    .rotate-icon {
      position: absolute;
      top: 0;
      right: 42px;
      height: 1.4em;
    }
  }

  .wtb-content {
    padding-top: 2em;
    color: $color10;
  }

  .wtb-system-message {
    padding-top: 1em;
    color: $color15;
  }
}
