@import "../../styles/base/_variables.scss";

.home-section {
  border-radius: 24px;
  padding: 25px !important;
  margin-right: 25px;
  margin-bottom: 25px;
  
  .col {
    padding: 31px 25px;
  }
  
  &.pmf-score {
    background: $color14;
    color: $color9;
  }

  &.trend {
    background-color: $color11;
  }

  &.audience {
    background-color: $color12;
    color: $color9;
  }

}

.audience {
  position: relative;

  .toogle-audience {
    position: absolute;
    top: 3.5em;
    right: 2em;
    width: 100%;
    margin-bottom: 10px;
    margin-top: -20px;
    text-align: right;

    input {
      margin-left: 0.5em;
    }
  }

  .audiences-container {
    margin-top: 5px;
    height: 300px;
    overflow-y: auto;
  }

  .audiences {
    display: flex;
    margin-bottom: 10px;
    border-radius: 5px;
    line-height: 125%;

    &.good-score {
      background-color: $color14;
    }

    &.bad-score {
      background-color: $color15;
    }

    .audience-item {
      padding: 0.25em 1em;

      &.audience {
        width: 40%;
      }

      &.score {
        width: 25%;
        text-align: right;
      }

      &.count {
        width: 20%;
      }

      &.revenue {
        width: 10%;
        text-align: right;
        margin-right: 5%;
      }

      &.select-audience {
        padding: 3px 0.5em;
        width: 5%;
        text-align: center;
      }
    }
  }
}

.pmf-score {
  position: relative;
  z-index: 0;

  .showcase-chart {
    z-index: 2;
    position: relative;

    .chart-area {
      width: 160px;
      height: 160px;
      margin: 30px auto;
    }
  }

  .score-value {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: 700; 
    z-index: 1;  
  }

  ul {
    margin: 1em 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 0;
    }
  }

  .color-box {
    width: 14px;
    height: 14px;
    border-radius: 3px;
    background-color: $color1;
    display: inline-block;
    margin-right: 1em;

    &.very-disappointed {
      background-color: $color12;
    }

    &.somewhat-disappointed {
      background-color: $color13;
    }

    &.not-disappointed {
      background-color: $color9;
    }
  }

  .percent {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.trend {
  .chart-area, .loading {
    margin: 1em 0;
    height: 220px;
  }

  .loading {
    text-align: center;
    line-height: 220px;
  }
}
