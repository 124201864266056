@import "../../styles/base/variables";

.step-counter {
  .step {
    overflow: hidden;
    display: inline-block;
    width: 38px;
    height: 38px;
    border-radius: 50px;
    border: 2px solid $color14;
    line-height: 34px;
    text-align: center;
    font-weight: 700;

    &.step-done, &.step-current {
      background-color: $color14;
      color: $color9;
    }

  
    &.step-next {
      background-color: $color9;
      color: $color14;
      border-color: $color14;
      
    }

  }

  .step-spacer {
    overflow: hidden;
    display: inline-block;
    width: 38px;
    height: 38px;

    &::after {
      display: inline-block;
      content: " ";
      margin-top: 18px;
      width: 38px;
      height: 2px;
      border: 2px solid $color14;
    }

    &.step-done-spacer::after {
      border-color: $color14;
    }
  }
}