$color20: #e39494;
$color21: #f2d8d8;

.form-layout {
  position: relative;
  
  .form-wrapper {
    height: 100vh;
  }

  .btn {
    &.btn-secondary {
      border: 1px solid $color20;
      background-color: $color21;
      font-size: 21px;
      line-height: 25.41px;
      color: $color20;
    }
  }
}

.pmf-form {
  font-family: 'Inter';
  font-size: 21px;
  line-height: 25.41px;
  display: flex;
  margin-top: 75px;

  height: calc(100% - 75px);

  .step {
    margin-bottom: 50px;
    width: 100%;

    .step-heading {
      margin-bottom: 25px;

      h1 {
        font-size: 21px;
        line-height: 140%;
        margin-bottom: 10px;
      }

      p {
        font-size: 15px;
        line-height: 18.15px;
      }
    }

    .step-inputs {
      margin-top: 45px;
    }

    .user-input {
      color: $color20;
    }

    .following-question {
      p {
        margin: 1em 0;
      }
    }

    .thankyou {
      text-align: center;

      h1 {
        font-size: 21px;
        line-height: 30px;
        margin-top: 50px;
        margin-bottom: 20px;
      }

      h3, p {
        font-size: 15px;
        margin-bottom: 20px;
      }
    }
  }

  input, textarea {
    color: $color20;
    font-size: 21px;
    line-height: 25.41px;

    border: none;
    border-bottom: 2px solid $color20;
    border-radius: 0;
    width: 100%;
    margin-bottom: 10px;
    outline-color: transparent;
    outline: none;
  }

  .options {
    .btn {
      font-weight: 400;
      font-size: 21px;
      line-height: 25.41px;
      margin-bottom: 10px;

      &:hover, &.option-selected {
        border-width: 3px;
        padding: 4px 10px;
      }
    }
  }

  textarea {
    padding-bottom: 8px;
  }

  input {
    padding-bottom: 8px;
    width: 100%;
  }
}

.step-progress {
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;

  font-family: 'Inter';
  font-size: 21px;
  line-height: 25.41px;

  .progress {
    border: 1px solid $color20;
    padding: 2px;
    border-radius: 10px;
    background-color: transparent;

    .progress-bar {
      background-color: $color20;
      border-radius: 10px;
    }
  }
    
}


.steps-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  
  .container {
    margin: 20px auto;
    
    button:first-child {
      margin-right: 5px;
    }
  }
}