@import "../../styles/base/_variables.scss";

.audience-manager {
  width: 100%;

  .audience-list-item {
    cursor: default;
    border: 1px solid $color11;
    background-color: $color11;
    height: 38px;
    line-height: 38px;
    padding: 0 8px;

    position: relative;
    width: 100%;
    border-radius: 3px;

    .delete-button {
      position: absolute;
      display: none;
      top: -1px;
      right: 12px;

      height: 32px;
      padding: 0;
    }

    .group-handle {
      position: absolute;
      display: none;
      top: -1px;
      right: 40px;
      padding: 0px 10px;
      cursor: move;
    }
  }

  .audience-list-item:hover {
    .delete-button, .group-handle {
      display: block;
    }

  }

  .audience-item-editable {
    padding: 6px 24px 6px 4px;
  }

}


.new-audience-form {
  &.row {
    padding: 32px 0 0 6px !important; 
  }

  input {
    margin-bottom: 12px;
  }

  .btn {
    text-align: center;
    display: inline-block;
    
    &.add-audience-button {
      padding: 12px 14epx;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
    }
  }


}